<script setup lang="ts">
import type { Category } from '@shopware-pwa/types';

const { count } = useCart();
const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const sidebarController = useModal();

const isMenuActive = ref<boolean | null>(false);
const isSearchActive = ref<boolean | null>(false);
const activeNavElem = ref<Category | null>(null);
const activeChildElem = ref<Category | null>(null);

// const promobar = getTranslatedProperty(null, 'customFields')
</script>
<template>
  <header
    class="top-bar z-200 max-w-superlarge fixed left-1/2 w-full -translate-x-1/2"
    aria-label="top-navigation"
  >
    <div
      v-if="isMenuActive || isSearchActive"
      class="backdrop bg-dark/40 z-100 fixed h-screen w-full"
      aria-hidden="true"
      @click="
        isMenuActive = false;
        isSearchActive = false;
      "
    >
      <button
        class="text-dark top-15 absolute left-1/2 hidden h-11 w-11 -translate-x-[150%] items-center justify-center rounded-full bg-white md:flex"
      >
        <span class="sr-only"> Close filter </span>
        <i class="icon i-custom-svg:cross block text-2xl" />
      </button>
    </div>
    <div
      class="header-inner lg:pt-15 lg:px-15 mx-auto flex w-full items-center space-x-4 px-4 py-6 sm:px-6"
    >
      <div class="flex min-w-10 items-center justify-start lg:min-w-12">
        <div class="order-2 ml-4 lg:order-1 lg:ml-0">
          <NuxtLink :to="formatLink(`/`)">
            <span class="sr-only">SN super.natural Online Shop</span>
            <img v-lazy-load 
              class="h-10 w-10 lg:h-12 lg:w-12"
              data-src="/logo/sn_logo_schwarz.svg"
              alt="SN Logo"
              width="40"
              height="40"
              fetchpriority="high"
            />
          </NuxtLink>
        </div>
        <div class="order-1 flex items-center justify-start lg:order-2">
          <LayoutSideMenu @open-cart-menu="sidebarController.open" />
        </div>
      </div>
      <NuxtLink
        :to="formatLink(`/`)"
        class="z-300 absolute left-1/2 !ml-0 -translate-x-1/2 md:hidden"
      >
        <span class="sr-only">SN super.natural Online Shop</span>
        <img v-lazy-load 
          class="h-10 w-10 lg:h-12 lg:w-12"
          data-src="/logo/sn_logo_schwarz.svg"
          alt="SN Logo"
          width="40"
          height="40"
          fetchpriority="high"
        />
      </NuxtLink>
      <div class="hidden flex-1 md:flex" aria-hidden="true" />
      <div
        class="top-navigation lg:right-15 absolute right-0 w-full lg:w-auto 2xl:w-[calc(50%-3.81rem)]"
      >
        <div
          class="navigation-bar z-200 relative mx-2 flex items-center justify-end rounded-full bg-white px-6 py-4 lg:justify-between"
        >
          <LayoutTopNavigation
            :is-menu-active="isMenuActive"
            @open-menu="isMenuActive = true"
            @close-menu="isMenuActive = false"
            @set-active-nav="(navElem: Category) => (activeNavElem = navElem)"
            @set-active-child-nav="() => (activeChildElem = null)"
          />

          <div class="flex items-center justify-end space-x-8">
            <!-- Search -->
            <div class="hidden lg:flex">
              <button
                class="group relative flex items-center bg-transparent"
                @click="isSearchActive = !isSearchActive"
              >
                <div
                  class="i-carbon-search text-dark hover:text-brand-primary h-6 w-6"
                />
                <span class="sr-only"
                  >{{ $t('cart.itemsInCart') }}, {{ $t('cart.viewCart') }}</span
                >
              </button>
            </div>
            <!-- Wishlist -->
            <div class="hidden lg:flex">
              <NuxtLink
                class="group relative flex items-center text-center"
                aria-label="wishlist"
                data-testid="wishlist-button"
                :to="formatLink(`/wishlist`)"
              >
                <div
                  :class="
                    wishlistCount > 0
                      ? 'i-custom-svg:heart-filled'
                      : 'i-custom-svg:heart'
                  "
                  class="text-dark hover:text-brand-primary h-6 w-6"
                />
                <span
                  v-if="wishlistCount > 0"
                  class="text-3 font-sm absolute flex h-6 w-6 items-center justify-center pt-1 text-center text-white"
                >
                  {{ wishlistCount > 100 ? '99+' : wishlistCount || '' }}
                </span>
              </NuxtLink>
            </div>
            <!-- Account -->
            <template class="hidden lg:flex">
              <AccountMenu />
            </template>
            <!-- Cart -->
            <div class="flex">
              <button
                class="group relative flex items-center bg-transparent"
                aria-label="Mini cart"
                data-testid="cart-button"
                @click="sidebarController.open"
              >
                <!-- Heroicon name: outline/shopping-bag -->
                <div
                  :class="
                    count > 0 ? 'i-custom-svg:cart-filled' : 'i-custom-svg:cart'
                  "
                  class="text-dark hover:text-brand-primary h-6 w-6"
                />
                <span
                  v-if="count > 0"
                  class="text-3 font-sm absolute flex h-6 w-6 items-center justify-center pt-2 text-center text-white"
                >
                  {{ count > 100 ? '99+' : count || '' }}
                </span>
                <span class="sr-only"
                  >{{ $t('cart.itemsInCart') }}, {{ $t('cart.viewCart') }}</span
                >
              </button>
            </div>
          </div>
        </div>
        <CheckoutSideCart :controller="sidebarController" />
        <LayoutFullNavigation
          :is-menu-active="isMenuActive"
          :active-nav-elem="activeNavElem"
          :active-child-elem="activeChildElem"
          @set-active-child-menu="
            (navElem: Category) => (activeChildElem = navElem)
          "
          @close-menu="isMenuActive = false"
        />
        <LayoutStoreSearch
          :is-search-active="isSearchActive"
          @close-search="isSearchActive = false"
        />
      </div>
      <SharedPromoBanner />
    </div>
  </header>
</template>
